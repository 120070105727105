/// <reference path="o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { TruncateIndexDBObjectStoreMode } from 'o365.pwa.types.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as ApiPwaOnlineSyncOptionsModule from 'o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.d.ts'

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiRequestOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiRequestOptions.ts");

    class ApiPwaOnlineSyncOptions implements ApiPwaOnlineSyncOptionsModule.ApiPwaOnlineSyncOptions {
        public readonly requestGuid: string;
        public readonly appId: string;
        public readonly viewName: string;
        public readonly originalViewName: string;
        // public readonly fields: any;
        // public readonly skip: number;
        // public readonly maxRecords: number;
        // public readonly distinctRows: boolean;
        // public readonly filterObject: any;
        // public readonly whereObject: any;
        // public readonly masterDetailObject: any;
        public readonly offlineDataType: string;
        public readonly dataObjectId: string;
        public readonly personID?: string;
        public readonly rowCountTimeout?: number;
        public readonly objectStoreIdOverride: string;
        public readonly truncateMode: TruncateIndexDBObjectStoreMode;
        public readonly appIdOverride?: string;
        public readonly databaseIdOverride?: string;
        public readonly deviceRef?: string;


        // public readonly indexedDbWhereExpression?: WhereExpression;

        constructor(options: ApiPwaOnlineSyncOptionsModule.IOnlineSyncBaseOptions) {
            this.requestGuid = options.requestGuid;
            this.appId = options.appId;
            this.viewName = options.viewName;
            this.originalViewName = options.originalViewName;
            // this.fields = options.fields;
            // this.skip = options.skip;
            // this.maxRecords = options.maxRecords;
            // this.distinctRows = options.distinctRows;
            // this.filterObject = options.filterObject;
            // this.whereObject = options.whereObject;
            // this.masterDetailObject = options.masterDetailObject;
            this.dataObjectId = options.dataObjectId;
            this.objectStoreIdOverride = options.objectStoreIdOverride;
            this.truncateMode = options.truncateMode;
            this.offlineDataType = options.offlineDataType;
            this.deviceRef = options.deviceRef;

        }
        static async fromRequest(request: Request): Promise<InstanceType<typeof ApiRequestOptions<ApiPwaOnlineSyncOptions>>> {
            const clonedRequest = request.clone();
            const body = await clonedRequest.json();

            return new ApiRequestOptions(request, new ApiPwaOnlineSyncOptions(body))
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.d.ts')>({ ApiPwaOnlineSyncOptions });
})();
